import { createRouter, createWebHashHistory } from "vue-router";

import Http404 from "./pages/Http404/Http404.vue";
import Login from "./pages/Login/Login.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("./pages/Dashboard/Dashboard.vue"),
  },

  //! ACCOUNT
  {
    path: "/account",
    name: "account",
    component: () => import("./pages/Account/Account.vue"),
  },

  //! TARGETS
  { path: "/targets", redirect: { path: "/targets/all" } },
  {
    path: "/targets/:engine",
    name: "targets",
    component: () => import("./pages/Targets/Targets.vue"),
  },
  {
    path: "/targets/:engine/:targetId/scan_settings",
    name: "scan_settings",
    component: () => import("./pages/Targets/scan_settings/nscan.vue"),
  },
  {
    path: "/targets/:engine/:targetId/webapp_settings",
    name: "webscan_settings",
    component: () => import("./pages/Targets/WebScan/Settings.vue"),
  },
  {
    path: "/targets/:engine/:targetId/cti_settings",
    name: "cti_settings",
    component: () => import("./pages/Targets/Cti/Settings.vue"),
  },

  //! GENERIC NEW TARGET ROUTE
  {
    path: "/targets/new/newTarget",
    name: "newTarget",
    component: () => import("./pages/Targets/new/newTarget.vue"),
  },

  //! NEW TARGET ROUTES
  {
    path: "/targets/new/DTI/:targetId?",
    name: "newDTI",
    component: () => import("./pages/Targets/new/DTI.vue"),
  },
  {
    path: "/targets/new/newNScan/:targetId?",
    name: "newNScan",
    component: () => import("./pages/Targets/new/nscan/nscan.vue"),
  },
  {
    path: "/targets/new/newCTI/:targetId?",
    name: "newCTI",
    component: () => import("./pages/Targets/new/DTI.vue"),
  },
  {
    path: "/targets/new/DTILite/:targetId?",
    name: "newDTILite",
    component: () => import("./pages/Targets/new/DTI.vue"),
  },
  {
    path: "/targets/new/web_scan/:targetId?",
    name: "newWebScan",
    component: () => import("./pages/Targets/new/WebScan/WebScan.vue"),
  },
  {
    path: "/targets/new/RRI/:targetId?",
    name: "newRRI",
    component: () => import("./pages/Targets/new/DTI.vue"),
  },

  //! MAIN
  {
    path: "/main/:engine",
    name: "main",
    component: () => import("./pages/Main/Main.vue"),
  },

  //! REPORTS
  {
    path: "/reports",
    component: () => import("./pages/Reports/Reports.vue"),
    children: [
      {
        path: ":engine",
        name: "reports",
        component: () => import("./pages/Reports/ByEngine.vue"),
      },
      {
        path: ":engine/:targetId",
        name: "reports-by-target",
        component: () => import("./pages/Reports/ByTarget.vue"),
      },
    ],
  },

  //! REPORT DETAILS
  {
    path: "/report/dti/:id",
    name: "report_dti",
    component: () => import("./pages/Reports/Report/DTIReport.vue"),
  },
  {
    path: "/report/nscan/:id",
    name: "report_nscan",
    component: () => import("./pages/Reports/Report/NScanReport.vue"),
  },
  {
    path: "/report/cti/:id",
    name: "report_cti",
    component: () => import("./pages/Reports/Report/CTIReport.vue"),
  },
  {
    path: "/report/dti_lite/:id",
    name: "report_dti_lite",
    component: () => import("./pages/Reports/Report/DTILiteReport.vue"),
  },
  {
    path: "/report/webapp/:id",
    name: "report_webapp",
    component: () => import("./pages/Reports/Report/WebScan/WebScanReport.vue"),
  },
  {
    path: "/report/rri/:id",
    name: "report_rri",
    component: () => import("./pages/Reports/Report/RRIReport.vue"),
  },

  //! RRI
  {
    path: "/rri",
    name: "rri",
    component: () => import("./pages/RRI/RRI.vue"),
  },

  //! DEEP WEB SEARCH
  // {
  //   path: "/search",
  //   name: "search",
  //   component: () => import("./pages/Search/Search.vue"),
  // },

  //! PHISHING ATTACK
  {
    path: "/phishing_attack",
    name: "phishing_attack",
    component: () => import("./pages/PhishingAttack/PhishingAttack.vue"),
  },

  //! INCIDENT RESPONSE
  {
    path: "/ir",
    name: "ir",
    component: () => import("./pages/IncidentResponse/IncidentResponse.vue"),
  },
  {
    path: "/ir/form/:id?",
    name: "ir-form",
    component: () => import("./pages/IncidentResponse/allowed/Form.vue"),
  },

  //! SMISHING ATTACK
  {
    path: "/smishing_attack",
    name: "smishing_attack",
    component: () => import("./pages/SmishingAttack/SmishingAttack.vue"),
  },

  //! CYBER THREAT
  {
    path: "/cti",
    name: "cti",
    component: () => import("./pages/CyberThreat/CyberThreat.vue"),
  },

  //! PENETRATION TEST
  {
    path: "/penetration_test",
    name: "penetration_test",
    component: () => import("./pages/PenetrationTest/PenetrationTest.vue"),
  },

  //! SECURITY ACADEMY
  {
    path: "/security_academy",
    name: "security_academy",
    component: () => import("./pages/SecurityAcademy/SecurityAcademy.vue"),
  },

  //! SECURITY ACADEMY
  {
    path: "/elearning_academy",
    name: "elearning_academy",
    component: () => import("./pages/ELearningAcademy/ELearningAcademy.vue"),
  },

  //! SOC AS A SERVICE
  {
    path: "/soc_as_a_service",
    name: "soc_as_a_service",
    component: () => import("./pages/SocAsService/SocAsService.vue"),
  },

  //! CODE REVIEW
  {
    path: "/code_review",
    name: "code_review",
    component: () => import("./pages/CodeReview/CodeReview.vue"),
  },

  //! VULNERABILITY EARLY WARNING
  {
    path: "/technology_vulnerability_monitoring",
    name: "vulnerability_early_warning",
    component: () => import("./pages/VulnerabilityEarlyWarning/VulnerabilityEarlyWarning.vue"),
  },

  //! WEBSCAN
  {
    path: "/webscan",
    name: "webscan",
    component: () => import("./pages/WebScan/WebScan.vue"),
  },

  //! CARD PROTECTION
  {
    path: "/card-protection",
    name: "card-protection",
    component: () => import("./pages/CardProtection/Index.vue"),
  },

  //! ABOUT
  // {
  //     path: "/about",
  //     name: "about",
  //     component: () => import("./pages/About/About.vue"),
  // },

  //! CONTACT US / TICKET
  {
      path: "/contact-us",
      name: "contact-us",
      component: () => import("./pages/ContactUs/ContactUs.vue"),
  },

  //! SECURITY MEASURES
  {
    path: "/security-measures",
    name: "security-measures",
    component: () => import("./pages/SecurityMeasures/SecurityMeasures.vue"),
  },

  //! CLOUD SECURITY
  {
    path: "/cloud_security",
    name: "cloud_security",
    component: () => import("./pages/CloudSecurity/CloudSecurity.vue"),
  },

  //! QUESTIONNAIRES
  {
    path: "/survey_:survey_type",
    name: "surveys",
    component: () => import("./pages/Questionnaires/Index.vue"),
  },
  {
    path: "/survey_:survey_type/form",
    name: "survey_form",
    component: () => import("./pages/Questionnaires/form/Form.vue"),
  },
  {
    path: "/survey_:survey_type/:id(\\[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/)",
    name: "survey",
    component: () => import("./pages/Questionnaires/view/View.vue"),
  },
  {
    path: "/survey_:survey_type/:id(\\[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/)/report",
    name: "survey_report",
    component: () => import("./pages/Questionnaires/report/Report.vue"),
  },

  //! 404 NOT FOUND ROUTE
  {
    path: "/http404",
    name: "http404",
    component: Http404,
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("./pages/Dashboard/Dashboard.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Add route guard for DTI-lite routes
router.beforeEach((to, from, next) => {
  if (
    to.path.startsWith('/main/dti_lite') ||
    to.path.startsWith('/targets/dti_lite') ||
    to.path.startsWith('/reports/dti_lite') ||
    to.path.startsWith('/report/dti_lite/')
  ) {
    // Redirect to dashboard
    next('/dashboard');
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//     window.scrollTo(0, 0);
//     if ((to.name !== 'http403') && !isAuthenticated()) next({
//         name: 'http403'
//     })
//     else next()
// })

export default router;
