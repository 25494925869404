export const ENV_VARS_PROD = {
  VUE_APP_ENV: "development",
  VUE_APP_IAM_REALM: "swascan",
  VUE_APP_IAM_CLIENT_ID: "swascan",
  VUE_APP_I18N_LOCALE: "it",
  VUE_APP_I18N_FALLBACK_LOCALE: "it",
  VUE_APP_NAME: "frontend",
  VUE_APP_OLD_PLATFORM: "https://security.swascan.com/",
  VUE_APP_RESET_PASSWORD: "https://security.swascan.com/accounts/password/reset/",
  VUE_APP_SIGN_UP: "https://security.swascan.com/accounts/signup/",
  VUE_APP_PERMITTED_LICENSES: "dti,nscan,phishing_attack,smishing_attack,ir,cti,cyber_threat,tvm,dti_lite,rri,survey_ict,survey_gdpr",
  VUE_APP_IAM_BASE_URL: "https://platform.swascan.com/auth",
  VUE_APP_API_BASE_URL: "https://core.platform.swascan.com/core",
  VUE_APP_BACKEND_BASE_URL: "https://backend.platform.swascan.com",
  VUE_APP_LICENSE_BASE_URL: "https://licensing.platform.swascan.com/licensing",
  VUE_APP_REST_BASE_URL: "https://rest.platform.swascan.com",
  VUE_APP_SWASCAN_INFO_EMAIL: "mailto:site@swascan.com",
  VUE_APP_VULNERABILITY_EARLY_WARNING_ENV_KEY: "",
  VUE_APP_FORCE_KEYCLOAK: "false",
  VUE_APP_FORCE_STANDALONE: "true",
  VUE_APP_PUBLIC_KEY: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxQ7lnnCjbEOScfMRSmN9PAtPjDGPCZ9T2JQ91lcZfE6DS9pYRJjGPPajK/7SaH6yPLecqCWHIiaXimn3WlczT6e5yTNMZTyocDR3lPa6q7kgNRDqKYnrNYpg1gIGB+Kx2JnMs1OYODsFK/6w+yE/6IWgpm+24J8n6fxkuk36+qxWR0iaKhzX8IUn9/WtHNfO8cNESY+FENqs5eERDO+JqZ4jiKKde9TsRrD5x3NxmqQTHC6/ZU32oWscQGZxaZIbPy292aLyeFWPI56hWZ0JpPeciMPabMEKHigVBGhmVZbkRZ9X5lGqaiiEzhlGo/kLIxIkJaJdHDwsFl0Cg51WhwIDAQAB
-----END PUBLIC KEY-----`,
};
