export const ENV_VARS_PREPROD = {
  VUE_APP_ENV: "development",
  VUE_APP_IAM_REALM: "swascan",
  VUE_APP_IAM_CLIENT_ID: "swascan",
  VUE_APP_I18N_LOCALE: "it",
  VUE_APP_I18N_FALLBACK_LOCALE: "it",
  VUE_APP_NAME: "frontend",
  VUE_APP_OLD_PLATFORM: "https://pre-prod.swascan.com/",
  VUE_APP_RESET_PASSWORD: "https://pre-prod.swascan.com/accounts/password/reset/",
  VUE_APP_SIGN_UP: "https://pre-prod.swascan.com/accounts/signup/",
  VUE_APP_PERMITTED_LICENSES: "dti,nscan,phishing_attack,smishing_attack,ir,cti,cyber_threat,tvm,dti_lite,webapp,rri,cardp,survey_ict,survey_gdpr",
  VUE_APP_IAM_BASE_URL: "https://iam.preplatform.com/auth",
  VUE_APP_API_BASE_URL: "https://core.preplatform.swascan.com/core",
  VUE_APP_BACKEND_BASE_URL: "https://backend.preplatform.swascan.com",
  VUE_APP_LICENSE_BASE_URL: "https://licensing.preplatform.swascan.com/licensing",
  VUE_APP_REST_BASE_URL: "https://rest.preplatform.swascan.com",
  VUE_APP_SWASCAN_INFO_EMAIL: "mailto:site@swascan.com",
  VUE_APP_VULNERABILITY_EARLY_WARNING_ENV_KEY: "PREPROD",
  VUE_APP_FORCE_KEYCLOAK: "true",
  VUE_APP_FORCE_STANDALONE: "false",
  VUE_APP_PUBLIC_KEY: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAi5CLhzP2ua7gyJpOK34l5A70/StrC4SRzEqB1hgdsxXAtMLjoFOmflIt5XFQ+H4zlg0w4veV9BDcLifvQ0bD14vf+HOBeJTbbCKtnm6A56ca+y312dKeu5kA+cxYqtbY74B8+syCTdvm0ahpHoApzex4Dh4ZFyHUqPbDLn+cPnTR3uGcJpRhzn/tFdsQcMGgIqM438MFOcYtYkFD/PZyUA6ZcmOrseXk9U7UHp8sWuOBZBGStsrTSw3pKjeinZNhwCH6IgAACRwVe621rkI9zfWgSs18KlZwn9VuxdYo3JHLbT7E/t6sHC4w/2h+NdsvYYnSZFsaxcDWg4N0wbxV8wIDAQAB
-----END PUBLIC KEY-----`,
};
