<template>
  <div :class="containerClass" @click="onWrapperClick">
    <Message
        v-if="showNotification"
        class="notification-error"
        severity="error"
        @close="setNotificationAsRead()"
    >{{ $t("notification_alert") }}
    </Message>
    <AppTopBar @menu-toggle="onMenuToggle" v-if="authenticated" />

    <transition name="layout-sidebar" v-if="authenticated">
      <div
          :class="sidebarClass"
          @click="onSidebarClick"
          v-show="isSidebarVisible()"
      >
        <div class="layout-logo">
          <router-link to="/">
            <img
                alt="Logo"
                class="logo"
                src="assets/layout/images/logo_tinexta.svg"
            />
          </router-link>
        </div>

        <ScrollPanel class="layout-scroll-panel-menu" style="width: 100%">
          <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />

          <!-- REPORT INFO -->
          <div v-if="state.reportSummary" class="p-mb-5">
            <div class="report-summary-label">{{ $t("general.Target") }}:</div>
            <div class="report-summary-value p-mb-3">
              {{ state.reportSummary.target_cleaned }}
            </div>
            <div class="report-summary-label">
              {{ $t("pages.dashboard.license_type") }}:
            </div>
            <div class="report-summary-value p-mb-3">
              {{ state.reportSummary.name }}
            </div>
            <div class="report-summary-label">
              {{ $t("general.scan_type") }}:
            </div>
            <div class="report-summary-value p-mb-3">
              {{ state.reportSummary.scanType }}
            </div>
            <div class="report-summary-label">
              {{ $t("general.scan_date") }}:
            </div>
            <div class="report-summary-value p-mb-3">
              {{ state.reportSummary.creation_time }}
            </div>
          </div>
          <!-- REPORT INFO -->
        </ScrollPanel>
      </div>
    </transition>

    <div class="layout-main" v-if="authenticated">
      <!-- <div class="p-grid">
        <div class="p-col p-pr-0 p-pl-0">
          <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>
      </div> -->
      <router-view />
    </div>
    <router-view v-if="!authenticated" />

    <AppFooter v-if="authenticated" />
    <DynamicDialog />
  </div>
</template>

<script>
import { licensesMethods } from "@/commons";
import AppFooter from "./AppFooter.vue";
import AppMenu from "./AppMenu.vue";
import AppTopBar from "./AppTopbar.vue";
import { ENV_VARS } from "./env_vars";
import { LicenseService, RouterService } from "./services";
import { useStore, watchAuthentication } from "./store";
import { Engine } from "./utilities";

export default {
  setup() {
    return {
      state: useStore().state,
      setState: useStore().setState,
      getValue: useStore().getValue,
    };
  },
  timer: null,
  data() {
    return {
      authenticated: false,
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      breadcrumbHome: { icon: "pi pi-home", to: "/" },
      isStaff: true,
      isTargetRole: true,
      isRawDataRole: true,
    };
  },
  computed: {
    showNotification() {
      // return this.authenticated && !this.getValue("notificationRead", null);
      return false;
    },
    showModalNotification() {
      // return this.authenticated && !this.getValue("notificationRead", null);
      return false;
    },
    breadcrumbItems() {
      return this.$router.options.routes.map((item) => {
        return { label: item.name.toUpperCase(), to: item.path };
      });
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
              this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
              this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.ripple === false,
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
    menu() {
      const permittedLicenses = ENV_VARS.VUE_APP_PERMITTED_LICENSES.split(",");
      const dtiLite = this.getDTILite(permittedLicenses, this.state.licenses);

      const isOnPrem = ENV_VARS.VUE_APP_ENV === "on-prem";

      // DTI
      const dti = [
        {
          id: "rri",
          label: this.$t("menu.rri"),
          icon: "search",
          iconType: "fa",
          to: "/rri",
        },
        {
          id: "dti",
          label: this.$t("menu.dti"),
          icon: "search",
          iconType: "fa",
        },
        {
          id: "cti",
          label: this.$t("menu.cyber_threat"),
          icon: "skull-crossbones",
          iconType: "fa",
          to: "/cti",
        },
        {
          id: "tvm",
          label: this.$t("menu.vulnerability_early_warning"),
          icon: "vuejs",
          iconType: "fab",
          to: "/technology_vulnerability_monitoring",
        },
        {
          id: "cardp",
          label: this.$t("menu.card_protection"),
          icon: "credit-card",
          iconType: "fa",
          to: "/card-protection",
        },
      ];
      if (dtiLite) {
        dti.splice(1, 0, dtiLite);
      }

      // MENU
      const menu = [
        {
          label: this.$t("menu.dashboard"),
          icon: "tachometer-alt",
          iconType: "fa",
          to: "/",
        },
        {
          label: this.$t("menu.my_sites"),
          icon: "table",
          iconType: "fa",
          to: "/targets/all",
        },
        {
          label: this.$t("menu.threat_intelligence"),
          title: true,
          style: "background-color: #3a3c43 !important",
          items: dti,
        },
        {
          label: this.$t("menu.technology_risk"),
          title: true,
          style: "background-color: #3a3c43 !important",
          items: [
            {
              id: "nscan",
              label: this.$t("menu.nscan"),
              icon: "eye",
              iconType: "fa",
            },
            {
              id: "webapp",
              label: this.$t("menu.webapp"),
              icon: "bug",
              iconType: "fa",
              to: "/webscan",
            },
            {
              label: this.$t("menu.penetration_test"),
              icon: "crosshairs",
              iconType: "fa",
              to: "/penetration_test",
            },
            {
              label: this.$t("menu.code_review"),
              icon: "code",
              iconType: "fa",
              to: "/code_review",
              // url:
              //     ENV_VARS.VUE_APP_OLD_PLATFORM +
              //     "dashboard/code_review/",
            },
          ],
        },
        {
          label: this.$t("menu.cloud"),
          title: true,
          style: "background-color: #3a3c43 !important",
          items: [
            {
              label: this.$t("menu.cloud_security"),
              icon: "cloud",
              iconType: "fa",
              to: "/cloud_security",
            },
          ]
        },
        {
          label: this.$t("menu.human_risk"),
          title: true,
          style: "background-color: #3a3c43 !important",
          items: [
            {
              label: this.$t("menu.phishing_attack"),
              icon: "envelope",
              iconType: "fa",
              to: "/phishing_attack",
            },
            {
              label: this.$t("menu.smishing_attack"),
              icon: "comment",
              iconType: "fa",
              to: "/smishing_attack",
            },
            {
              label: this.$t("menu.security_academy"),
              icon: "school",
              iconType: "fa",
              to: "/security_academy",
            },
            {
              label: this.$t("menu.elearning_academy"),
              icon: "chalkboard-teacher",
              iconType: "fa",
              to: "/elearning_academy",
            },
          ],
        },
        {
          label: this.$t("menu.data_breach_mgmt"),
          title: true,
          style: "background-color: #3a3c43 !important",
          items: [
            {
              label: this.$t("menu.incident_response"),
              icon: "first-aid",
              iconType: "fa",
              to: '/ir',
            },
            {
              label: this.$t("menu.soc_as_service"),
              icon: "compass",
              iconType: "fa",
              to: "/soc_as_a_service",
            },
          ]
        },
      ];
      const surveys = this.getSurveysMenus(permittedLicenses)
      if (surveys.length > 0) {
        const surveyItem = {
          label: this.$t("menu.risk_management"),
          title: true,
          style: "background-color: #3a3c43 !important",
          items: surveys
        }
        menu.splice(6, 0, surveyItem);
      }

      if (ENV_VARS.VUE_APP_ENV !== "on-prem") {
        menu.push({
          label: this.$t("menu.contact_us"),
          icon: "envelope",
          iconType: "fa",
          to: "/contact-us",
        });
      }

      if (isOnPrem) {
        const newMenu = [];
        for (const item of menu) {
          if (item.items && item.items.length > 0) {
            const items = [];
            for (let i = 0; i < item.items.length; i++) {
              let subitem = item.items[i];
              if (!subitem.id) continue;
              if (permittedLicenses.indexOf(subitem.id) > -1) {
                if (['tvm', 'cardp'].includes(subitem.id)) {
                  continue;
                } else if (['survey_gdpr', 'survey_ict', 'survey_test'].includes(subitem.id)) {
                  // Do nothing, john snow
                } else {
                  subitem = this.checkLicense(subitem, permittedLicenses);
                }
                items.push(subitem);
              }
            }
            if (items.length > 0) {
              item.items = items;
              newMenu.push(item);
            }
          } else if (!item.id) {
            newMenu.push(item);
          }
        }

        menu.splice(0, menu.length);
        for (const item of newMenu) {
          menu.push(item);
        }
      } else {
        for (const item of menu) {
          if (item.items) {
            let n = 0;
            for (let subitem of item.items) {
              if (['tvm', 'cardp'].includes(subitem.id)) {
                const isPermittedLicense = permittedLicenses.includes(subitem.id)
                if (!isPermittedLicense) {
                  item.items.splice(n, 1);
                }
              } else if (['survey_gdpr', 'survey_ict', 'survey_test'].includes(subitem.id)) {
                // Do nothing, john snow
              } else {
                subitem = this.checkLicense(subitem, permittedLicenses);
              }

              n++;
            }
          }
        }
      }
      return menu;
    },
    scanType() {
      return this.state.reportSummary.full_mode
          ? this.$t("general.scan_full")
          : this.$t("general.scan_light");
    },
  },
  methods: {
    ...licensesMethods,
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;
      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element && element.classList) element.classList.add(className);
      else if (element) element.className += " " + className;
    },
    removeClass(element, className) {
      if (element && element.classList) element.classList.remove(className);
      else if (element)
        element.className = element.className.replace(
            new RegExp(
                "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
                "gi"
            ),
            " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    setLicenses() {
      this.getLicenses();
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => this.getLicenses(), 60 * 10 * 1000);
      this.setState({
        user: RouterService.keycloak
            ? RouterService.keycloak.tokenParsed
            : RouterService.decoded,
        isStaff: RouterService.keycloak
            ? RouterService.keycloak.tokenParsed &&
            RouterService.keycloak.tokenParsed.realm_access &&
            RouterService.keycloak.tokenParsed.realm_access.roles &&
            RouterService.keycloak.tokenParsed.realm_access.roles.indexOf(
                "staff"
            ) > -1
                ? true
                : false
            : RouterService.decoded &&
            RouterService.decoded.realm_access &&
            RouterService.decoded.realm_access.roles &&
            RouterService.decoded.realm_access.roles.indexOf("staff") > -1
                ? true
                : false,
        isTargetRole: RouterService.keycloak
            ? RouterService.keycloak.tokenParsed &&
            RouterService.keycloak.tokenParsed.realm_access &&
            RouterService.keycloak.tokenParsed.realm_access.roles &&
            RouterService.keycloak.tokenParsed.realm_access.roles.indexOf(
                "target"
            ) > -1
                ? true
                : false
            : RouterService.decoded &&
            RouterService.decoded.realm_access &&
            RouterService.decoded.realm_access.roles &&
            RouterService.decoded.realm_access.roles.indexOf("target") > -1
                ? true
                : false,
        isRawDataRole: RouterService.keycloak
            ? RouterService.keycloak.tokenParsed &&
            RouterService.keycloak.tokenParsed.realm_access &&
            RouterService.keycloak.tokenParsed.realm_access.roles &&
            RouterService.keycloak.tokenParsed.realm_access.roles.indexOf(
                "raw-data"
            ) > -1
                ? true
                : false
            : RouterService.decoded &&
            RouterService.decoded.realm_access &&
            RouterService.decoded.realm_access.roles &&
            RouterService.decoded.realm_access.roles.indexOf("raw-data") > -1
                ? true
                : false,
      });
    },
    setLogged() {
      this.setLicenses();
      if (this.$router.currentRoute._value.path === "/") {
        this.$router.replace({ path: "/dashboard" });
      }
    },
    setNotificationAsRead() {
      sessionStorage.setItem("notificationRead", JSON.stringify(true));
    },
    getDTILite(permittedLicenses, userLicenses) {
      /* if (!permittedLicenses.includes("dti_lite")) return null;
      if (!userLicenses.find((el) => el.engine === Engine.DTI_LITE))
        return null;
      return {
        id: "dti_lite",
        label: this.$t("menu.dti_lite"),
        icon: "search",
        iconType: "fa",
      }; */
      return null;
    },
    checkLicense(subitem, permittedLicenses) {
      if (permittedLicenses.indexOf(subitem.id) === -1) {
        subitem.url = subitem.url || ENV_VARS.VUE_APP_OLD_PLATFORM;
        return subitem;
      }
      for (const license of this.state.licenses) {
        if (license.engine === subitem.id) {
          if (license.id === null) {
            // silence is golden
          } else {
            subitem.to = "";
            const toUrl = (str) =>
                permittedLicenses.indexOf(license.engine) > -1
                    ? {
                      to: {
                        name: str,
                        params: {
                          engine: license.engine,
                        },
                      },
                    }
                    : {
                      url: ENV_VARS.VUE_APP_OLD_PLATFORM,
                    };

            subitem.items = [
              {
                label: this.$t("menu.main"),
                ...toUrl("main"),
              },
              {
                label: this.$t("menu.targets_list"),
                ...toUrl("targets"),
              },
              {
                label: this.$t("menu.reports_list"),
                ...toUrl("reports"),
              },
            ];
          }
        }
      }

      return subitem;
    },
    emitDialogMessage () {
      if (!this.showModalNotification) return;
      this.$swal({
        title: null,
        html: this.$t("notification_alert"),
        icon: 'info',
        showConfirmButton: true,
        showDenyButton: false,
      }).then(() => {
        this.setNotificationAsRead()
      })
    },
    getSurveysMenus (permittedLicenses) {
      // INIT
      const output = []
      // const list = this.state.licenses.filter((el) => el.engine.includes('survey'))
      const list = ['survey_gdpr', 'survey_ict', 'survey_test']

      for (let i = 0; i < list.length; i++) {
        const engine = list[i]
        if (permittedLicenses.indexOf(engine) <= -1) continue
        const license = this.state.licenses.find((el) => el.engine === engine)
        if (engine === 'survey_test' && license) {
          output.push({
            id: `survey_test`,
            label: this.$t(`menu.${engine}`),
            icon: 'tasks',
            iconType: "fa",
            to: `/${engine}`,
          })
        } else {
          output.push({
            id: `${engine}`,
            label: this.$t(`menu.${engine}`),
            icon: (engine === 'survey_gdpr' ? "check-square" : 'tasks'),
            iconType: "fa",
            to: `/${engine}`,
          })
        }
      }

      return output
    }
  },
  licenseService: null,
  created() {
    this.licenseService = new LicenseService();
  },
  mounted() {
    watchAuthentication().subscribe((authenticated) => {
      if (authenticated) {
        this.authenticated = true;
        this.setLogged();
        this.emitDialogMessage()
      } else if (!authenticated) {
        this.authenticated = false;

        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    });
    this.$nextTick(() => {
      this.emitDialogMessage()
    })
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>

<style lang="scss" scoped>
@import "./assets/layout/_variables";

.logo {
  width: 52%;
}

.version {
  text-align: center;
  padding-bottom: 20px;
}

.report-summary-label {
  color: $topbarRightBgColor;
  font-weight: bolder;
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
}

.report-summary-value {
  margin-left: 2rem;
}

.notification-error {
  position: fixed;
  width: 100%;
  border-width: 0px !important;
  z-index: 9999;
  color: white;
  border-radius: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  .p-message-icon {
    color: white !important;
  }
}

::v-deep(.notification-error .p-message-icon) {
  color: white !important;
}

::v-deep(.notification-error .p-message-close) {
  color: white !important;
}
</style>

